import React from 'react';
import { Popup } from 'semantic-ui-react';

import './index.scss';

export const ClippedContent = ({ children, popup = true, popupContent, onClose = () => { } , position='top left'}) => {
    const trigger = <div className='ClippedContent'>{children}</div>;

    return (
        popup ? (
            <Popup
                trigger={trigger}
                content={popupContent || children}
                inverted
                on='hover'
                onClose={onClose}
                position={position}
            />
        ) : trigger
    );
}
