import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { buildYup } from 'json-schema-to-yup';

export const VALIDATION_PATTERNS = {
    alphanumeric: /^[A-Za-z0-9\:\-\s]+$/,
    alphanumeric_search: /[^A-Za-z0-9\:\-\s\@\.]/,//last 2 for emails
    alphanumeric_w_hyphen_and_or_ampersand_search: /[^A-Za-z0-9\&\-\s]/,
    postal_code: /^[0-9]{5}?$/,
    website: /^((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    us_phone: /^[2-9]\d{9}$/,
    no_us_phone: /^(?![2-9]\d{9}$).*$/,
}

const CNAM_FORBBIDEN_WORDS = ["Blocked", "Unavailable"];

function maxlength(length) {
    const regex = `^[\\s\\S]{0,${length}}$`;
    const test = new RegExp(regex, 'g');
    return this.matches(test, { message: `The max length for this field is ${length} characters`, excludeEmptyString: true });
}

function minlength(length) {
    const regex = `^[\\s\\S]{${length},}$`;
    const test = new RegExp(regex, 'g');
    return this.matches(test, { message: `The min length for this field is ${length} characters `, excludeEmptyString: true });
}

function only_numbers() {
    return this.matches(/^\d+$/, { message: 'This field should contain only numbers', excludeEmptyString: true });
}
function us_postal_code() {
    return this.matches(/^[0-9]{5}?$/, { message: 'This field should contain only US postal codes', excludeEmptyString: true });
}

function noemoji() {
    return this.test('noemoji', 'This field should not contain emojis', value => !value.match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])|(^|\s)((;|:)(D|-|\\|\/))(?=\s|[^"-(*+\-/->@-~]|$)/));
}

function no_excessive_whitespaces() {
    return this.test('no_excessive_whitespaces', "This field cannot have only or excessive (4 in a row) whitespaces", value => !value.match(/\s{4}/m));
}

function noWhitespaceOnly() {
    return this.test('no_whitespace_only', 'This field cannot contain only whitespaces', value => !Boolean(value) || value?.replace(/(\r\n|\n|\r)/gm, "").trim().length > 0)
}

function password_check() {
    return this.matches(/^(?=.{6,})(?=.*[A-Za-z])(?=.*[0-9])(?=.*\W).+/, { message: 'The minimum length of a password is 6 characters and should contain at least one number, symbol & letter', excludeEmptyString: true })
}

function phone_check() {
    return this.matches(VALIDATION_PATTERNS.us_phone, { message: 'please enter a valid US phone number', excludeEmptyString: true })
}

function no_phones() {
    return this.matches(VALIDATION_PATTERNS.no_us_phone, { message: 'This field should not be a phone number', excludeEmptyString: true })
}

function website() {
    return this.matches(/^((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, { message: 'please enter a valid url', excludeEmptyString: true })
}

function optional() {
    return this.transform(value => (typeof value === 'number' && isNaN(value)) ? 0 : value);
}

function simple_alphanumeric() {
    return this.matches(/^[A-Za-z0-9\:\-\s]+$/, { message: 'This field should only contains letters, numbers, " - " or " : " ', excludeEmptyString: true });
}

function simple_alphanumeric_w_hyphen_only() {
    return this.matches(/^[A-Za-z0-9\-\s]+$/, { message: 'This field should only contains letters, numbers, or " - " ', excludeEmptyString: true });
}

function simple_alphanumeric_w_hyphen_and_or_ampersand() {
    return this.matches(/^[A-Za-z0-9\&\-\s]+$/, { message: 'This field should only contains letters, numbers, " - ", or " & " ', excludeEmptyString: true });
}

function simple_alphanumeric_w_dividers() {
    return this.matches(/^[A-Za-z0-9\&\+\-\:\/\\\s]+$/, { message: 'This field should only contains letters, numbers, " - ", " & ", " + ", " : ", " / ", and " \\ " ', excludeEmptyString: true });
}

function percentage() {
    return this.matches(/^[1-9][0-9]?$|^100$/, { message: 'This field should only contain a number from 1 to 100', excludeEmptyString: true });
}

function number_two_decimals() {
    return this.matches(/^\d+(\.\d{1,2})?$/, { message: 'This field should only contain a number with 2 decimals', excludeEmptyString: true });
}

function date_format() {
    return this.matches(/^(0[1-9]|1[0-2])(\/|-|\.)(0[1-9]|1\d|2\d|3[01])(\/|-|\.)(?:(?:1[6-9]|[2-9]\d)?\d{2})$/, { message: 'This field should only contain, " - ", " . ", and " / " ', excludeEmptyString: true });
}

function time_format() {
    return this.matches(/^[0-9\-\:\/\s]+$/, { message: 'This field should only contain, " - ", " : ", and " / " ', excludeEmptyString: true });
}

function job_title() {
    return this.matches(/^[a-zA-Z0-9&\-_/. ]+$/, { message: 'This field should only contain, " - ", " : ", and " & " ', excludeEmptyString: true });
}

function cnam() {
    return this.matches(/^[^\s][a-zA-Z0-9.,&-_'\s]*$/, { message: 'This field should only contain, " - ", " _ ", and " \' " and shouldn\'t start by space', excludeEmptyString: true });
}

function forbidden_words(words) {
    const regex_parts = words.map((word) => `(?!.*\\b${word}\\b)`)
    const message_parts = words.map((word, index) => {
        return `${index === words.length - 1 ? (
            index > 0 ? " or" : ""
        ) : (
            index > 0 ? "," : ""
        )} "${word}"`
    })
    const forbidden_words_regex = new RegExp(`^${regex_parts.join('')}.*`, 'i');
    return this.matches(forbidden_words_regex, { message: `This field shouldn\'t contain ${message_parts.join('')}`, excludeEmptyString: true });
}

yup.addMethod(yup.string, 'maxlength', maxlength);
yup.addMethod(yup.string, 'minlength', minlength);
yup.addMethod(yup.string, 'only_numbers', only_numbers);
yup.addMethod(yup.string, 'us_postal_code', us_postal_code);
yup.addMethod(yup.string, 'noemoji', noemoji);
yup.addMethod(yup.string, 'no_excessive_whitespaces', no_excessive_whitespaces);
yup.addMethod(yup.string, "no_whitespace_only", noWhitespaceOnly);
yup.addMethod(yup.string, 'password', password_check);
yup.addMethod(yup.string, 'phone', phone_check);
yup.addMethod(yup.string, 'no_phone', no_phones);
yup.addMethod(yup.string, "optional", optional);
yup.addMethod(yup.string, "website", website);
yup.addMethod(yup.string, "simple_alphanumeric", simple_alphanumeric);
yup.addMethod(yup.string, "simple_alphanumeric_w_hyphen_only", simple_alphanumeric_w_hyphen_only);
yup.addMethod(yup.string, "simple_alphanumeric_w_hyphen_and_or_ampersand", simple_alphanumeric_w_hyphen_and_or_ampersand);
yup.addMethod(yup.string, "simple_alphanumeric_w_dividers", simple_alphanumeric_w_dividers)
yup.addMethod(yup.string, "percentage", percentage);
yup.addMethod(yup.string, "number_two_decimals", number_two_decimals);
yup.addMethod(yup.string, "date_format", date_format);
yup.addMethod(yup.string, "time_format", time_format);
yup.addMethod(yup.string, "job_title", job_title);
yup.addMethod(yup.string, "cnam", cnam);
yup.addMethod(yup.string, "forbidden_words", forbidden_words);


const requireValidator = (validator) => {
    return (required) => {
        if (required) {
            validator.required("This field is required");
            return validator;
        }
        return validator;
    };
}

export const VALIDATORS = {
    TEAM_NAME: yup.string().minlength(2).maxlength(64).simple_alphanumeric().no_excessive_whitespaces(),
    FIRST_NAME: yup.string().simple_alphanumeric().no_excessive_whitespaces().minlength(2).maxlength(50),
    LAST_NAME: yup.string().simple_alphanumeric().no_excessive_whitespaces().minlength(2).maxlength(50),

    EVENT_NAME: yup.string().minlength(2).maxlength(41).no_whitespace_only().no_excessive_whitespaces().noemoji(),
    EVENT_DESCRIPTION: yup.string().maxlength(512).no_whitespace_only().no_excessive_whitespaces(),

    PHONE_NUMBER: yup.string().phone(),
    NO_EMOJI: yup.string().noemoji(),
    NO_ONLY_WHITESPACE: yup.string().no_excessive_whitespaces(),
    PHONE_NUMBER_EXTENSION: yup.string().maxlength(11).only_numbers(),
    EMAIL_ADDRESS: yup.string().maxlength(256).email("This field should be a valid email"),

    STREET_ADDRESS: yup.string().no_excessive_whitespaces().noemoji().minlength(2).maxlength(100),
    TOWN: yup.string().simple_alphanumeric().no_excessive_whitespaces().minlength(2).maxlength(60),
    STATE: yup.string().simple_alphanumeric().no_excessive_whitespaces().minlength(2).maxlength(50),
    COUNTRY: yup.string().simple_alphanumeric().no_excessive_whitespaces().minlength(2).maxlength(55),
    US_POSTAL_CODE: yup.string().us_postal_code(),

    EMAIL_TEMPLATE_NAME: yup.string().maxlength(64).no_whitespace_only().noemoji(),
    TEXT_TEMPLATE_NAME: yup.string().maxlength(64).no_whitespace_only().noemoji(),
    TITLE: yup.string().simple_alphanumeric().no_excessive_whitespaces().minlength(2).maxlength(60),
    SCHOOL_OR_DEGREE: yup.string().minlength(2).maxlength(100),
    DEPARTMENT: yup.string().simple_alphanumeric().no_excessive_whitespaces().minlength(2).maxlength(64),
    CREDIT_CARD_NUMBER: yup.string().minlength(2).maxlength(64).only_numbers(),
    CVV: yup.string().minlength(2).maxlength(4).only_numbers(),
    IP_ADDRESS: yup.string().maxlength(12).only_numbers(),
    BUSINESS_NAME: yup.string().simple_alphanumeric_w_hyphen_and_or_ampersand().no_excessive_whitespaces().minlength(2).maxlength(100),
    MASTER_NAME: yup.string().simple_alphanumeric_w_hyphen_and_or_ampersand().no_excessive_whitespaces().minlength(2).maxlength(100),
    DUNS_NUMBER: yup.string().maxlength(9).only_numbers(),
    STICKY_NOTE: yup.string().minlength(2).maxlength(1500),
    DESCRIPTION: yup.string().minlength(3).maxlength(256).no_whitespace_only(),
    SEARCH_FIELD: yup.string().minlength(2),
    PASSWORD: yup.string().password(),
    PASSWORD_CONFIRMATION: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    WEBSITE: yup.string().website(),
    SIMPLE_INPUT: yup.string().simple_alphanumeric().no_excessive_whitespaces(),
    PERCENTAGE: yup.string().percentage(),
    TWO_DECIMALS: yup.string().number_two_decimals(),
    SUFFIX: yup.string().no_whitespace_only().maxlength(5).minlength(2),
    DATE_FORMAT: yup.string().date_format(),
    TIME_FORMAT: yup.string().time_format(),
    NOTE: yup.string().minlength(2).maxlength(256),
    JOB_TITLE: yup.string().job_title(),

    CALLER_ID_DISPLAY: yup.string().simple_alphanumeric().maxlength(12).no_whitespace_only().no_excessive_whitespaces(),
    NUMBER_LABEL: yup.string().simple_alphanumeric().maxlength(64).no_whitespace_only().no_excessive_whitespaces(),
    CNAM: yup.string().cnam().forbidden_words(CNAM_FORBBIDEN_WORDS).no_phone().maxlength(15).no_whitespace_only().no_excessive_whitespaces()
}

Object.keys(VALIDATORS).forEach(key => {
    VALIDATORS[key] = requireValidator(VALIDATORS[key]);
});



export const generateResolver = (schema, excludes = []) => {
    return yupResolver(yup.object().shape(schema, excludes));
}

export const getErrorMessage = (error) => {
    return error && error.message;
}

export const buildResolverFromDefinition = (definition, config) => {
    return yupResolver(buildYup(definition, config));
}

export { yup };
