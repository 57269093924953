import React from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import { ControlledPopup, CheckboxDropdown } from 'dyl-components';

const renderActionButton = ({ isExisting, editMode, isValid, onEdit, onConfirmEdit, onCancelEdit, remove, hasMoreItems }) => (
    <Form.Group className='EditableLabels__actionButtons'>
        {isExisting ?
            editMode ? <React.Fragment>
                <Form.Field>
                    <Icon
                        link
                        name='x'
                        color='red'
                        onClick={onCancelEdit}
                    />
                </Form.Field>
                <Form.Field>
                    <Icon
                        link
                        name='check'
                        color='blue'
                        disabled={!isValid}
                        onClick={onConfirmEdit}
                    />
                </Form.Field>
            </React.Fragment> :
                <React.Fragment>
                    <Form.Field>
                        <Icon
                            link
                            name='fas fa-pencil'
                            color='blue'
                            onClick={onEdit}
                        />
                    </Form.Field>
                    <ControlledPopup trigger={
                        <Form.Field>
                            <Icon
                                link
                                name='fas fa-trash-alt'
                                color='blue'
                            />
                        </Form.Field>} onConfirm={remove} />
                </React.Fragment>
            :
            hasMoreItems ?
                <Form.Field>
                    <Icon
                        link
                        name={'times'}
                        onClick={remove}
                    />
                </Form.Field>
                : <React.Fragment />
        }
    </Form.Group>
);

const LabelField = ({ type, itemIndex, valueIndex, placeholder, name, control, value, editMode, isFieldEditable, hasMoreItems, hasMoreValues, isDirty, isValid,
    options, remove, modifyCheckForDuplicateProps = () => { }, checkDuplicate = () => { }, shouldRenderActionButton,
    getFieldDuplicateProps = () => { }, isExisting = false, onEdit, onConfirmEdit, onCancelEdit }) => {

    switch (type) {
        case 'input':
            return <Controller
                name={`${name}.${itemIndex}.value.${valueIndex}`}
                control={control}
                defaultValue={value}
                rules={{
                    required: {
                        value: (hasMoreItems && hasMoreValues) || editMode,
                        message: 'This field should not be empty'
                    },
                    maxLength: {
                        value: 41,
                        message: 'This field should contain at most 41 characters'
                    },
                    pattern: {
                        value: /^[ -~]+$/,
                        message: 'This field should not contain emojis'
                    },
                    validate: {
                        no_excessive_whitespaces: (value) => {
                            return ((value === undefined || value?.length === 0) && !hasMoreItems) ||
                                (value?.length !== 0 && !!value?.trim()) ||
                                'This field cannot only contain white spaces'
                        },
                        no_duplicate: (value) => {
                            if (!isExisting && getFieldDuplicateProps(itemIndex, 'lastLabelChecked') !== value) {
                                modifyCheckForDuplicateProps('update_isChecking', getFieldDuplicateProps(itemIndex, 'id'), true);
                                modifyCheckForDuplicateProps('update_checkDuplicateTimeoutHandler', getFieldDuplicateProps(itemIndex, 'id'),
                                    () => { checkDuplicate(value, `${name}.${itemIndex}.value.${valueIndex}`, itemIndex, valueIndex, getFieldDuplicateProps(itemIndex, 'id')) }
                                );
                            }

                            if (value === '' || getFieldDuplicateProps(itemIndex, 'lastLabelChecked') === value) {
                                modifyCheckForDuplicateProps('update_isChecking', getFieldDuplicateProps(itemIndex, 'id'), false);
                            }

                            return !getFieldDuplicateProps(itemIndex, 'hasDuplicate') ||
                                getFieldDuplicateProps(itemIndex, 'duplicateMessage');
                        }
                    },

                }}
                render={({ field: { value, name, onChange }, fieldState: { error } }) => (

                    <Form.Group>
                        <Form.Input
                            name={name}
                            value={value}
                            onChange={(_, { name, value }) => {
                                modifyCheckForDuplicateProps('update_isChecking', getFieldDuplicateProps(itemIndex, 'id'),
                                    value.replace(/\s+/g, ' ').trim()
                                );
                                onChange({ target: { name, value } });
                            }}
                            className={`EditableLabels__input EditableLabels__input${isExisting ? `--existing${editMode ? '-onEdit' : ''}` : ''}`}
                            placeholder={placeholder}
                            readOnly={!editMode && isFieldEditable}
                            error={isDirty && error && error.message && {
                                content: error.message,
                                pointing: 'below'
                            }}
                            loading={getFieldDuplicateProps(itemIndex, 'isChecking')}
                        />
                        {shouldRenderActionButton && renderActionButton({ isExisting, remove, hasMoreItems, editMode, onEdit, onConfirmEdit, onCancelEdit, isValid })}
                    </Form.Group>
                )}
            />
        case 'checkbox_dropdown':
            return <Controller
                name={`${name}.${itemIndex}.value.${valueIndex}`}
                control={control}
                defaultValue={value}
                rules={{
                    required: {
                        value: hasMoreItems || hasMoreValues,
                        message: 'This field should not be empty'
                    }
                }}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Group>
                        <Form.Field
                            className='EditableLabels__checkboxDropdown'
                            control={CheckboxDropdown}
                            onChange={(_, { name, value }) => { onChange({ target: { name, value } }) }}
                            placeholder={placeholder}
                            name={name}
                            value={value}
                            selection
                            options={options}
                            editable={editMode && isFieldEditable}
                            text={value && value.length > 0 ? options.filter(option => value.includes(option.value)).map(option => option.text).join(', ') : ''}
                            error={isDirty && error && error.message && {
                                content: error.message,
                                pointing: 'below'
                            }}
                        />
                        {shouldRenderActionButton && renderActionButton({ isExisting, remove, hasMoreItems, editMode, onEdit, onConfirmEdit, onCancelEdit, isValid })}
                    </Form.Group>
                )}
            />
        default:
            return <React.Fragment>

            </React.Fragment>
    }
}

export default LabelField;

