import React, { useState } from "react";
import { Icon, Checkbox, Header, Popup } from "semantic-ui-react";
import { Table, AvatarGroup, ControlledPopup } from "dyl-components";

function ActionBar({
    item,
    onTaskComplete,
    onTaskRemove,
    onTaskCall,
    onTaskText,
    hasEmailIntegrations,
    onTaskEmailNoIntegrationsContent,
    onSequenceTaskComplete,
    onSequenceTaskRemove,
    onSequenceTaskCall,
    onSequenceTaskText,
    onSequenceTaskEmail,
    onRefresh,
    setModal,
    setContactId,
    onSequenceTaskReschedule
}) {
    if (
        item?.activity_type === "Task" ||
        item?.activity_type === "SequenceTask"
    ) {
        // TODO: implement onCall && onText
        const { onRemove, onComplete, onCall, onText, onEmail } = {
            Task: {
                onRemove: onTaskRemove,
                onComplete: onTaskComplete,
                onCall: onTaskCall,
                onText: onTaskText,
            },
            SequenceTask: {
                onRemove: onSequenceTaskRemove,
                onComplete: onSequenceTaskComplete,
                onCall: onSequenceTaskCall,
                onText: onSequenceTaskText,
                onEmail: onSequenceTaskEmail,
            },
        }[item.activity_type];

        const getIconClassName = (action) => {
            const actionText = action.split(" ")[0];
            switch (actionText) {
                case "Call":
                    return "fa-solid fa-phone-flip";
                case "Email":
                    return "fa-solid fa-envelope";
                case "Text":
                    return "fa-solid fa-message";
                default:
                    return "";
            }
        };

        const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

        const getEmailTaskContent = () => {
            const canEmail =
                item.contacts &&
                item.contacts.length > 0 &&
                Boolean(item.contacts[0].email);
            if (hasEmailIntegrations && canEmail) {
                setContactId(item.contacts[0].id);
                return (
                    <Icon
                        size="large"
                        link
                        className={"fa-solid fa-envelope"}
                        color="primary"
                        style={{ marginTop: 2 }}
                        onClick={() => {
                            setModal("email");
                        }}
                        disabled={!canEmail}
                    />
                );
            } else {
                return (
                    <Popup
                        trigger={
                            <Icon
                                size="large"
                                link
                                className={"fa-solid fa-envelope"}
                                color="primary"
                                style={{ marginTop: 2 }}
                                onClick={() => {
                                    setIsEmailModalOpen(true);
                                }}
                                disabled={!canEmail}
                            />
                        }
                        content={onTaskEmailNoIntegrationsContent(
                            item.contacts[0],
                            setIsEmailModalOpen
                        )}
                        open={isEmailModalOpen}
                        on="click"
                        position="bottom right"
                        pinned
                        basic
                        wide
                        disabled={!canEmail}
                    />
                );
            }
        };

        const getActionButton = (action) => {
            if (action && action !== "To-Do") {
                if (action === "Email") {
                    return getEmailTaskContent();
                }
                return (
                    <Icon
                        size="large"
                        link
                        className={`${getIconClassName(action)}`}
                        color="primary"
                        style={{ marginTop: 2 }}
                    />
                );
            }
        };
        const isComplete = item.status?.toLowerCase()?.includes("complete");
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    alignItems: "center",
                    justifyContent: "left",
                    paddingLeft: "20px",
                }}
            >
                <Checkbox
                    className="SequenceTime__taskCheckbox"
                    style={{ marginRight: 6 }}
                    checked={isComplete}
                    disabled={isComplete}
                    onClick={() => {
                        if (!isComplete) {
                            onComplete(item.id, item.action);
                        }
                    }}
                />
                {getActionButton(item.action)}
                { item?.activity_type === "SequenceTask" ? <Icon
                    size="large"
                    link
                    className="fa-solid fa-calendar"
                    color="primary"
                    style={{ marginTop: 2 }}
                    onClick={() => {onSequenceTaskReschedule(item.id)}}
                    disabled={isComplete}
                /> : null} 
                <ControlledPopup
                    header={
                        <Header as="h4" textAlign="center">
                            Are you sure?
                        </Header>
                    }
                    trigger={
                        <Icon
                            size="big"
                            link
                            className="fa-solid fa-xmark redIcon"
                            disabled={isComplete}
                        />
                    }
                    onConfirm={() => {
                        onRemove(item.id, onRefresh);
                    }}
                    disabled={isComplete}
                    position={"top center"}
                />
            </div>
        );
    } else return <></>;
}

function EventRow({
    item,
    onEventClick,
    onContactClick,
    onTaskComplete,
    onTaskRemove,
    onTaskCall,
    onTaskText,
    hasEmailIntegrations,
    onTaskEmailNoIntegrationsContent,
    onSequenceTaskComplete,
    onSequenceTaskRemove,
    onSequenceTaskCall,
    onSequenceTaskText,
    onSequenceTaskEmail,
    onRefresh,
    setModal,
    setContactId,
    onSequenceTaskReschedule
}) {
    function getStatusIcon({ status, source, type, isConfirmed = true }) {
        // status is 'Pending' or 'Completed'
        // source is 'System' or 'Integrated'
        // type is 'Event', 'Task' or 'SequenceTask'
        return (
            <Icon
                name={`circle ${isConfirmed ? "" : "outline"}`}
                className={`
                IconStatus
                IconStatus__${type} 
                IconStatus__${type}__${status}
                IconStatus__${type}__${source}
            `}
            />
        );
    }

    return (
        <React.Fragment>
            <Table.Cell />
            <Table.Cell>
                {getStatusIcon({
                    status: item.status,
                    source: item.source,
                    type: item.activity_type,
                })}
                {item.allDay ? "" : item?.hour}
            </Table.Cell>
            <Table.Cell>
                <b>{item?.action}</b>
                <br />
                {item?.status}
            </Table.Cell>
            <Table.Cell>
                <a
                    style={{ cursor: "pointer" }}
                    onClick={() => onEventClick(item.id)}
                >
                    <b>{item?.name}</b>
                </a>
                <br />
                {item?.label}
            </Table.Cell>
            <Table.Cell>{item.notes || "No notes"}</Table.Cell>
            <Table.Cell style={{ "text-align": "center" }}>
                <AvatarGroup
                    persons={item.contacts || []}
                    size={"3em"}
                    onClick={onContactClick}
                />
            </Table.Cell>
            <Table.Cell style={{ "text-align": "center" }}>
                <ActionBar
                    item={item}
                    onTaskComplete={onTaskComplete}
                    onTaskRemove={onTaskRemove}
                    onTaskCall={onTaskCall}
                    onTaskText={onTaskText}
                    hasEmailIntegrations={hasEmailIntegrations}
                    onTaskEmailNoIntegrationsContent={
                        onTaskEmailNoIntegrationsContent
                    }
                    onSequenceTaskComplete={onSequenceTaskComplete}
                    onSequenceTaskRemove={onSequenceTaskRemove}
                    onSequenceTaskCall={onSequenceTaskCall}
                    onSequenceTaskText={onSequenceTaskText}
                    onSequenceTaskEmail={onSequenceTaskEmail}
                    onRefresh={onRefresh}
                    setModal={setModal}
                    setContactId={setContactId}
                    onSequenceTaskReschedule={onSequenceTaskReschedule}
                />
            </Table.Cell>
        </React.Fragment>
    );
}

function CollapsibleRow({ date, items, goToDay }) {
    const [isRowCollapsed, setIsRowCollapsed] = useState(false);
    let isDisabled = items.length <= 0;
    if (isDisabled) {
        return (
            <Table.Row disabled={isDisabled}>
                <Table.Cell>
                    <Icon name="chevron circle down" />
                </Table.Cell>
                <Table.Cell
                    className={`DateGroupHeader${
                        isDisabled ? "__disabled" : ""
                    }`}
                >
                    {date}
                </Table.Cell>
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
            </Table.Row>
        );
    }

    return (
        <Table.CollapsibleRow
            disabled={isDisabled}
            collapsed={isRowCollapsed}
            onToggle={() => {
                setIsRowCollapsed(!isRowCollapsed);
            }}
            subrowContent={items}
            children={[
                <Table.Row disabled={isDisabled}>
                    <Table.Cell onClick={goToDay} className="DateGroupHeader">
                        {date}
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                </Table.Row>,
            ]}
        />
    );
}

export default function Rows({
    isGroupedByDate,
    items,
    goToDay,
    onEventClick,
    onContactClick,
    onTaskComplete,
    onTaskRemove,
    onTaskCall,
    onTaskText,
    hasEmailIntegrations,
    onTaskEmailNoIntegrationsContent,
    onSequenceTaskComplete,
    onSequenceTaskRemove,
    onSequenceTaskCall,
    onSequenceTaskText,
    onSequenceTaskEmail,
    onRefresh,
    setModal,
    setContactId,
    onSequenceTaskReschedule
}) {
    return isGroupedByDate
        ? Object.entries(items).map(([date, { ts, events }]) => (
              <CollapsibleRow
                  date={date}
                  items={events.map((item) => (
                      <Table.CollapsibleRowContent key={item.id}>
                          <EventRow
                              item={item}
                              onEventClick={onEventClick}
                              onContactClick={onContactClick}
                              onTaskComplete={onTaskComplete}
                              onTaskRemove={onTaskRemove}
                              onTaskCall={onTaskCall}
                              onTaskText={onTaskText}
                              hasEmailIntegrations={hasEmailIntegrations}
                              onTaskEmailNoIntegrationsContent={
                                  onTaskEmailNoIntegrationsContent
                              }
                              onSequenceTaskComplete={onSequenceTaskComplete}
                              onSequenceTaskRemove={onSequenceTaskRemove}
                              onSequenceTaskCall={onSequenceTaskCall}
                              onSequenceTaskText={onSequenceTaskText}
                              onSequenceTaskEmail={onSequenceTaskEmail}
                              onRefresh={onRefresh}
                              setModal={setModal}
                              setContactId={setContactId}
                              onSequenceTaskReschedule={onSequenceTaskReschedule}
                          />
                      </Table.CollapsibleRowContent>
                  ))}
                  onEventClick={onEventClick}
                  onContactClick={onContactClick}
                  goToDay={() => {
                      goToDay(ts);
                  }}
                  onTaskComplete={onTaskComplete}
                  onTaskRemove={onTaskRemove}
                  onTaskCall={onTaskCall}
                  onTaskText={onTaskText}
                  hasEmailIntegrations={hasEmailIntegrations}
                  onTaskEmailNoIntegrationsContent={
                      onTaskEmailNoIntegrationsContent
                  }
                  onSequenceTaskComplete={onSequenceTaskComplete}
                  onSequenceTaskRemove={onSequenceTaskRemove}
                  onSequenceTaskCall={onSequenceTaskCall}
                  onSequenceTaskText={onSequenceTaskText}
                  onSequenceTaskEmail={onSequenceTaskEmail}
                  onRefresh={onRefresh}
                  setModal={setModal}
                  setContactId={setContactId}
              />
          ))
        : items.map((item) => (
              <Table.Row key={item.id}>
                  <EventRow
                      item={item}
                      onEventClick={onEventClick}
                      onTaskComplete={onTaskComplete}
                      onTaskRemove={onTaskRemove}
                      onTaskCall={onTaskCall}
                      onTaskText={onTaskText}
                      hasEmailIntegrations={hasEmailIntegrations}
                      onTaskEmailNoIntegrationsContent={
                          onTaskEmailNoIntegrationsContent
                      }
                      onSequenceTaskComplete={onSequenceTaskComplete}
                      onSequenceTaskRemove={onSequenceTaskRemove}
                      onSequenceTaskCall={onSequenceTaskCall}
                      onSequenceTaskText={onSequenceTaskText}
                      onSequenceTaskEmail={onSequenceTaskEmail}
                      onRefresh={onRefresh}
                      setModal={setModal}
                      setContactId={setContactId}
                      onSequenceTaskReschedule={onSequenceTaskReschedule}
                      onContactClick={onContactClick}
                  />
              </Table.Row>
          ));
}
