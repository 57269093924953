import moment from 'moment-timezone';
import { TimezoneList } from './DYLTZList';
let DEFAULT_TIMEZONE = "America/New_York";



export default class DateTimeUtils {
    static DATE_FORMAT = 'MM-DD-YYYY';
    static CALENDAR_API_DATE_FORMAT = 'YYYY-MM-DD';
    static WEEKDAY_DATE_FORMAT = "MM/DD ddd";
    static DATETIME_FORMAT = 'MM-DD-YYYY hh:mm A';
    static DATETIME_LONG_FORMAT = 'ddd MMM DD YYYY hh:mm:ss';
    static DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    static MONTH_DAY_TIME_FORMAT = `MMM DD, ${DateTimeUtils.TIME_FORMAT}`;
    static TIME_FORMAT = 'hh:mm A';
    static TIME_FORMAT_SECONDS = 'hh:mm:ss';
    static WORD_DATE_FORMAT = 'MMM DD, YYYY';
    static WORD_DATE_LONG_FORMAT = 'MMMM DD, YYYY';
    static WORD_MONTH_FORMAT = 'MMM';
    static WORD_MONTH_LONG_FORMAT = 'MMMM';
    static WORD_YEAR_FORMAT = 'YYYY';
    static WORD_DAY_FORMAT = 'ddd';
    static WORD_DAY_LONG_FORMAT = 'dddd';
    static MONTH_DAY_FORMAT = 'M[/]D';

    static WORD_DATETIME_FORMAT = `${DateTimeUtils.WORD_DATE_FORMAT} ${DateTimeUtils.TIME_FORMAT}`;

    static DEFAULT_TIMEZONE_OPTION = "America/New_York";
    static TIMEZONES = TimezoneList;

    static getTimeZone() {
        //right now only the browser
        return DEFAULT_TIMEZONE || this.DEFAULT_TIMEZONE_OPTION;
    }

    static setTimezone(timezone = this.DEFAULT_TIMEZONE_OPTION) {
        DEFAULT_TIMEZONE = timezone;
        moment.tz.setDefault(timezone);
        /* use this as an option when "read browser becomes a fetaure" 
        
        Intl.DateTimeFormat().resolvedOptions().timeZone;
        */
    }

    static getTimeElapsedFrom(epochTime) {
        return moment.unix.tz(epochTime).fromNow();
    }

    static formatEpoch(epochTime, format = 'h:mm A') {
        return moment.unix(epochTime).format(format);
    }

    static getOffset = (timezone) => {
        // return (DateTimeUtils.TIMEZONES.find(({ value }) => value === timezone) || { offset: 0 }).offset;
    }

    // static convertMilitaryTo12HourFormat(militaryTime) {
    //     return new Date('1970-01-01T' + militaryTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    // }

    static convertUnixTimeToDate(epochTime, timestamp = DEFAULT_TIMEZONE) {
        const tempDate = moment.unix(epochTime).toDate();
        return moment.tz(tempDate, timestamp);
    }

    static getCurrentTime(format = 'hh:mm A') {
        return moment().tz(DEFAULT_TIMEZONE).format(format);
    }

    static getDifference(date1, date2, unit = 'day', format = DateTimeUtils.DATE_FORMAT) {
        return moment(date1, format).diff(moment(date2, format), unit);
    }

    static getNumberOfBusinessDays(date1, date2, unit = 'day', format = DateTimeUtils.DATE_FORMAT, countSaturdays = true, countSundays = true) {
        const startDate = moment(date2, format);
        const endDate = moment(date1, format);
        const days = endDate.diff(startDate, unit);
        let weekendDays = 0;
        if (!countSaturdays || !countSundays) {
            let dateIncrement = startDate.clone();
            const isStartSunday = dateIncrement.day() === 0;
            let afterFirstLoop = false;
            while (dateIncrement.isSameOrBefore(endDate)) {
                dateIncrement = isStartSunday ? (afterFirstLoop ? dateIncrement.day(7) : dateIncrement) : dateIncrement.day(6);
                if (dateIncrement.isSameOrBefore(endDate) && (isStartSunday ? !countSundays : !countSaturdays)) {
                    weekendDays += 1;
                }
                dateIncrement = isStartSunday ? dateIncrement.day(6) : dateIncrement.day(7);
                if (dateIncrement.isSameOrBefore(endDate) && (isStartSunday ? !countSaturdays : !countSundays)) {
                    weekendDays += 1;
                }
                afterFirstLoop = true;
            }
        }
        return days - weekendDays;
    }

    static getCurrentDate(format = DateTimeUtils.DATE_FORMAT, unix = false) {
        const currentDate = moment().clone();
        currentDate.tz(DateTimeUtils.getTimeZone());

        return (unix) ? currentDate.unix() : currentDate.format(format);

    }

    static isBeforeOrOnCurrentDate(date, format = 'MM-DD-YYYYTHH:mm:SS', includeCurrentDate = true) {
        const currentDate = moment().tz(DEFAULT_TIMEZONE);
        if (!includeCurrentDate) return moment(date, format).tz(DEFAULT_TIMEZONE).isBefore(currentDate);
        return moment(date, format).tz(DEFAULT_TIMEZONE).isSameOrBefore(currentDate);
    }

    static isAfterOrOnCurrentDate(date, format = 'MM-DD-YYYYTHH:mm:SS', includeCurrentDate = true) {
        const currentDate = moment().tz(DEFAULT_TIMEZONE);
        if (!includeCurrentDate) return moment(date, format).tz(DEFAULT_TIMEZONE).isAfter(currentDate);
        return moment(date, format).tz(DEFAULT_TIMEZONE).isSameOrAfter(currentDate);
    }

    static getLast(number, time_unit = 'week', unix = true, isFromCurrentDate) {
        const currentDate = moment(this.getCurrentDate(), DateTimeUtils.DATE_FORMAT);
        const last = this.getTimeUnitsFromToday(!isFromCurrentDate ? currentDate.startOf(time_unit) : currentDate, number, `${time_unit}s`);
        if (unix) {
            return this.getUnixTime(last, DateTimeUtils.DATE_FORMAT);
        }
        return last;
    }

    static getNext(number, time_unit = 'week', unix = true, isFromCurrentDate) {
        const currentDate = moment(this.getCurrentDate(), DateTimeUtils.DATE_FORMAT);
        const next = this.getTimeUnitsFromToday(!isFromCurrentDate ? currentDate.startOf(time_unit) : currentDate, number, `${time_unit}s`, 'addition');
        if (unix) {
            return this.getUnixTime(next, DateTimeUtils.DATE_FORMAT);
        }
        return next;
    }

    static getTimezoneCurrentDay = (timezone) => {
        return moment().utc().tz(timezone).format(DateTimeUtils.DATE_FORMAT);
    }

    static getDateRange(value, date = this.getCurrentDate(), format = DateTimeUtils.DATE_FORMAT, timeUnits = 'day', unix = false, timezone = DEFAULT_TIMEZONE) {
        const currentDate = moment.tz(date, format, timezone);
        let start, end
        switch (value) {
            case 'today':
                start = currentDate.clone().startOf('day')
                end = currentDate.clone().endOf('day')
                break;
            case 'yesterday':
                start = currentDate.clone().startOf('day').subtract(1, 'day')
                end = currentDate.clone().endOf('day').subtract(1, 'day')
                break;
            case 'this_week':
                start = moment(currentDate).clone().startOf('week')
                end = moment(currentDate).clone().endOf('week')
                break;
            case 'last_week':
                start = currentDate.clone().startOf('week').subtract(7, 'days')
                end = currentDate.clone().endOf('week').subtract(7, 'days')
                break;
            case 'this_month':
                start = moment(currentDate).clone().startOf('month')
                end = moment(currentDate).clone().endOf('month')
                break;
            case 'last_month':
                start = currentDate.clone().startOf('month').subtract(1, 'month')
                end = currentDate.clone().endOf('month').subtract(1, 'month')
                break;
            case 'year_to_date':
                start = moment(currentDate).clone().startOf('year')
                end = moment(currentDate).clone()
                break;
            case 'last_year':
                start = currentDate.clone().startOf('year').subtract(1, 'year')
                end = currentDate.clone().endOf('year').subtract(1, 'year')
                break;
            case 'specify':
                start = currentDate.clone().startOf(timeUnits)
                end = currentDate.clone().endOf(timeUnits)
                break;
        }

        return {
            start: unix ? start.unix() : start.format(format),
            end: unix ? end.unix() : end.format(format)
        }
    }

    static getTimeUnitsFromToday(date, value, unit, operation = 'subtraction', format = DateTimeUtils.DATE_FORMAT, unix = false) {
        let ts;
        if (operation === 'subtraction') {
            ts = moment(date, format).subtract(value, unit).format(format);
        } else {
            ts = moment(date, format).add(value, unit).format(format);
        }
        if (unix) {
            return this.getUnixTime(ts, format);
        }
        return ts;
    }

    static changeFormat(date, currentFormat, newFormat, timezone = DEFAULT_TIMEZONE) {
        return moment(date, currentFormat).tz(timezone).format(newFormat);
    }

    static getUnixTime(readableDateTime, readableDateFormat = 'MM-DD-YYYY HH:mm:SS', timezone = DEFAULT_TIMEZONE) {
        return moment.tz(readableDateTime, readableDateFormat, timezone).unix();
    }

    static convertToMilitaryTime(AMPMTime, AMPMTimeFormat = 'h:mm A') {
        return moment(AMPMTime, AMPMTimeFormat).format('HH:mm:SS');
    }

    static convertTimeUnits(time, audioDuration) {
        let format = 'mm:ss';
        if(audioDuration > 3600000){
            format = 'HH:mm:ss';
        }
        return moment.utc(moment.duration({ seconds: time }).asMilliseconds()).format(format);
    }

    static getDay(unixTime) {
        const currentDate = moment().tz(DEFAULT_TIMEZONE);
        let agendaDate = moment.unix(unixTime);

        if (currentDate.isSame(agendaDate, 'days')) {
            return "Today";
        }
        if (currentDate.clone().add(1, 'days').isSame(agendaDate, 'days')) {
            return "Tomorrow";
        }
        if (currentDate.clone().subtract(1, 'days').isSame(agendaDate, 'days')) {
            return "Yesterday";
        }

        return agendaDate.format('MMM D');
    }

    static getWeek(unixTime) {
        const currentDate = moment().tz(DEFAULT_TIMEZONE);
        let agendaDate = moment.unix(unixTime);

        if (currentDate.clone().add(1, 'weeks').isSame(agendaDate, 'weeks')) {
            return "Next Week";
        }
        if (currentDate.isSame(agendaDate, 'weeks')) {
            return "This Week";
        }
        if (currentDate.clone().subtract(1, 'weeks').isSame(agendaDate, 'weeks')) {
            return "Last Week";
        }
        if (currentDate.clone().subtract(2, 'weeks').isSame(agendaDate, 'weeks')) {
            return "Last Two Weeks";
        }

        return agendaDate.isBefore(currentDate) ? "Past" : "Future";
    }

    static getMonth(unixTime) {
        //Technically should be called isCurrentMonth as it returns This Month or nothing, however, kept the naming convention used in the rest of this file
        const currentDate = moment().tz(DEFAULT_TIMEZONE);
        const agendaDate = moment.unix(unixTime);

        if (currentDate.isSame(agendaDate, 'month')) {
            return 'This Month'
        }
        if (currentDate.clone().subtract(1, 'month').isSame(agendaDate, 'month')) {
            return 'Last Month'
        }
    }

    static getYear(unixTime) {
        //Technically should be called isCurrentMonth as it returns This Month or nothing, however, kept the naming convention used in the rest of this file
        const currentDate = moment().tz(DEFAULT_TIMEZONE);
        const agendaDate = moment.unix(unixTime);

        if (currentDate.isSame(agendaDate, 'year')) {
            return 'This Year'
        }
        if (currentDate.clone().subtract(1, 'year').isSame(agendaDate, 'year')) {
            return 'Last Year'
        }

        return agendaDate.isBefore(currentDate) ? "Past" : "Future";
    }

    static groupDates = (dates, isAscending) => {
        const future = dates.filter(({ ts }) => (
            DateTimeUtils.getWeek(ts, false) === "Future" &&
            DateTimeUtils.getMonth(ts, false) !== 'This Month'
        ));
        const next_week = dates.filter(({ ts }) => DateTimeUtils.getWeek(ts, false) === "Next Week");
        const tomorrow = dates.filter(({ ts }) => (
            DateTimeUtils.getWeek(ts, false) !== "Next Week" &&
            DateTimeUtils.getDay(ts, false) === "Tomorrow"
        ));
        const today = dates.filter(({ ts }) => DateTimeUtils.getDay(ts, false) === "Today");
        const yesterday = dates.filter(({ ts }) => DateTimeUtils.getDay(ts, false) === 'Yesterday');
        const this_week = dates.filter(({ ts }) => (
            DateTimeUtils.getDay(ts, false) !== 'Tomorrow' &&
            DateTimeUtils.getDay(ts, false) !== 'Today' &&
            DateTimeUtils.getDay(ts, false) !== 'Yesterday' &&
            DateTimeUtils.getWeek(ts, false) === 'This Week'
        ));
        const last_week = dates.filter(({ ts }) => (
            DateTimeUtils.getDay(ts, false) !== 'Yesterday' &&
            DateTimeUtils.getWeek(ts, false) === 'Last Week'
        ));
        const this_month = dates.filter(({ ts }) => (
            DateTimeUtils.getWeek(ts, false) !== "Future" &&
            DateTimeUtils.getWeek(ts, false) !== "Next Week" &&
            DateTimeUtils.getDay(ts, false) !== 'Today' &&
            DateTimeUtils.getDay(ts, false) !== 'Yesterday' &&
            DateTimeUtils.getWeek(ts, false) !== 'This Week' &&
            DateTimeUtils.getWeek(ts, false) !== 'Last Week' &&
            DateTimeUtils.getMonth(ts, false) === 'This Month'
        ));

        const last_month = dates.filter(({ ts }) => (
            DateTimeUtils.getMonth(ts, false) !== 'This Month' &&
            DateTimeUtils.getDay(ts, false) !== 'Today' &&
            DateTimeUtils.getDay(ts, false) !== 'Yesterday' &&
            DateTimeUtils.getWeek(ts, false) !== 'This Week' &&
            DateTimeUtils.getWeek(ts, false) !== 'Last Week' &&
            DateTimeUtils.getMonth(ts, false) === 'Last Month'
        ));

        const this_year = dates.filter(({ ts }) => (
            DateTimeUtils.getWeek(ts, false) !== "Future" &&
            DateTimeUtils.getWeek(ts, false) !== "Next Week" &&
            DateTimeUtils.getMonth(ts, false) !== 'This Month' &&
            DateTimeUtils.getDay(ts, false) !== 'Today' &&
            DateTimeUtils.getDay(ts, false) !== 'Yesterday' &&
            DateTimeUtils.getWeek(ts, false) !== 'This Week' &&
            DateTimeUtils.getWeek(ts, false) !== 'Last Week' &&
            DateTimeUtils.getMonth(ts, false) !== 'Last Month' &&
            DateTimeUtils.getYear(ts, false) === 'This Year'
        ));

        const last_year = dates.filter(({ ts }) => (
            DateTimeUtils.getMonth(ts, false) !== 'This Month' &&
            DateTimeUtils.getDay(ts, false) !== 'Today' &&
            DateTimeUtils.getDay(ts, false) !== 'Yesterday' &&
            DateTimeUtils.getWeek(ts, false) !== 'This Week' &&
            DateTimeUtils.getWeek(ts, false) !== 'Last Week' &&
            DateTimeUtils.getMonth(ts, false) !== 'Last Month' &&
            DateTimeUtils.getYear(ts, false) === 'Last Year'
        ));

        const past = dates.filter(({ ts }) => (
            DateTimeUtils.getMonth(ts, false) !== 'This Month' &&
            DateTimeUtils.getDay(ts, false) !== 'Today' &&
            DateTimeUtils.getDay(ts, false) !== 'Yesterday' &&
            DateTimeUtils.getWeek(ts, false) !== 'This Week' &&
            DateTimeUtils.getWeek(ts, false) !== 'Last Week' &&
            DateTimeUtils.getMonth(ts, false) !== 'Last Month' &&
            DateTimeUtils.getYear(ts, false) !== 'Last Year' &&
            DateTimeUtils.getYear(ts, false) !== 'This Year' &&
            DateTimeUtils.getWeek(ts, false) === 'Past'
        ))

        const groupedDates = [
            {
                name: 'Future', dateTimes: future
            },
            {
                name: 'Next Week', dateTimes: next_week
            },
            ...(isAscending ? [
                {
                    name: 'This Week', dateTimes: this_week
                },
            ] : []),
            {
                name: 'Tomorrow', dateTimes: tomorrow
            },
            {
                name: 'Today', dateTimes: today
            },
            {
                name: 'Yesterday', dateTimes: yesterday
            },
            ...(!isAscending ? [
                {
                    name: 'This Week', dateTimes: this_week
                },
            ] : []),
            {
                name: 'Last Week', dateTimes: last_week
            },
            {
                name: 'This Month', dateTimes: this_month
            },
            {
                name: 'Last Month', dateTimes: last_month
            },
            {
                name: 'This Year', dateTimes: this_year
            },
            {
                name: 'Last Year', dateTimes: last_year
            },
            {
                name: 'Past', dateTimes: past
            }
        ].filter(({ dateTimes }) => dateTimes.length > 0);
        if (!isAscending) {
            return groupedDates
        }
        return groupedDates.reverse()
    }

    static groupItemsByDate = ({ ts }) => {
        return moment.unix(ts).startOf('day').unix();
    }

    static getStartOf = (ts, time_unit) => {
        return moment.unix(ts).startOf(time_unit).unix();
    }

    static getEndOf = (ts, time_unit) => {
        return moment.unix(ts).endOf(time_unit).unix();
    }

    static getTimeUnits = (ts, value, unit = 'day', operation = 'subtraction') => {
        // the same as getTimeUnitsFromToday but using unix timestamp
        const momentUnix = moment.unix(ts)
        if (operation === 'subtraction') {
            return momentUnix.subtract(value, unit).unix();
        } else {
            return momentUnix.add(value, unit).unix();
        }
    }

    static getEndOfDay = (ts) => {
        return moment.unix(ts).utc().endOf('day').unix();
    }

    static getStartOfTheMonth = () => {
        return moment().startOf('month').unix();
    }

    static getEndOfTheMonth = () => {
        return moment().endOf('month').unix();
    }

    static getStartOfYear = () => {
        return moment().startOf('year').unix();
    }

    static convertTimeToSeconds = (time, format = 'HH:mm:ss A') => {
        return moment(time, format).diff(moment().startOf('day'), 'seconds');
    }

    static convertSecondsToTime = (seconds, format = 'hh:mm A') => {
        return moment().startOf('day').seconds(seconds).format(format);
    }

    static isValid = (dateString, dateFormat = DateTimeUtils.DATE_FORMAT) => {
        return moment(dateString, dateFormat, true).isValid();
    }

    static getDateTerm = (numberOfDays) => {
        switch (numberOfDays) {
            case 30: return "Monthly";
            case 120: return "Quarterly";
            case 182: return "Semi-anually";
            case 365: return "Anually";
            default: return `${numberOfDays} day${numberOfDays !== 1 ? 's' : ''}`;
        }
    }

    static getNumberOfHoursAndMinutes = (numberOfSeconds) => {
        const numberOfHours = `${Math.floor(numberOfSeconds / 3600)}`;
        const numberOfMinutes = `${Math.floor((numberOfSeconds - (numberOfHours * 3600)) / 60)}`;
        return `${numberOfHours.padStart(2, '0')}:${(numberOfMinutes.padStart(2, '0'))}`;
    }
    static getNumberOfHoursAndMinutes = (numberOfSeconds) => {
        const numberOfHours = `${Math.floor(numberOfSeconds / 3600)}`;
        const numberOfMinutes = `${Math.floor((numberOfSeconds - (numberOfHours * 3600)) / 60)}`;
        return `${numberOfHours.padStart(2, '0')}:${(numberOfMinutes.padStart(2, '0'))}`;
    }

    static getDuration = (start, end, timeunit = 'days') => {
        const startDate = moment(start);
        const endDate = moment(end);
        const duration = moment.duration(endDate.diff(startDate));

        switch (timeunit) {
            case 'minutes':
                return duration.asMinutes();
            case 'hours':
                return duration.asHours();
            case 'days':
                return duration.asDays();
            case 'weeks':
                return duration.asWeeks();
            case 'months':
                return duration.asMonths();
            case 'years':
                return duration.asYears();
            default:
                return duration.asDays();
        }
    }

    static getTimeDropdownDefaults = (defaultAddition = 30) => {
        const intervals = [0, 15, 30, 45];
        const startDateTime = moment(this.getCurrentDate(DateTimeUtils.DATETIME_FORMAT, false, false), DateTimeUtils.DATETIME_FORMAT).add(defaultAddition, 'minutes');
        const hour = startDateTime.hour();
        const minute = startDateTime.minute();

        if (minute >= intervals[3]) {
            startDateTime.hour(hour === 23 ? 0 : hour + 1);
            startDateTime.minute(intervals[0]);
        } else if (minute >= intervals[2] && minute < intervals[3])
            startDateTime.minute(intervals[3]);
        else if (minute >= intervals[1] && minute < intervals[2])
            startDateTime.minute(intervals[2]);
        else if (minute >= intervals[0] && minute < intervals[1])
            startDateTime.minute(intervals[1]);

        const endDateTime = moment(startDateTime).add(1, 'hour');

        return {
            startDate: startDateTime.format(this.WORD_DATE_FORMAT),
            startTime: startDateTime.format(this.TIME_FORMAT),
            endDate: endDateTime.format(this.WORD_DATE_FORMAT),
            endTime: endDateTime.format(this.TIME_FORMAT)
        };
    }

    static dateIsBeforeAnotherDate = (date, anotherDate, unit = "minutes", format = DateTimeUtils.TIME_FORMAT, checkSame = false) => {
        if (!checkSame) {
            return moment(date, format).isBefore(moment(anotherDate, format), unit)
        }
        return moment(date, format).isSameOrBefore(moment(anotherDate, format), unit);
    }


    static generateTimezoneOptions = (shortList = true) => {
        // return DateTimeUtils.TIMEZONES.map(({ value, text, major_cities }) => ({ key: value, value: value, text, description: major_cities }));
        //use false to return ALL options (remove the filter)
        const rawList = DateTimeUtils.TIMEZONES.sort((a, b) => {
            if (a.short_list === b.short_list) {
                return a.text < b.text ? -1 : 1
            } else {
                return a.short_list > b.short_list ? -1 : 1
            }
        });

        const interimList = (shortList) ? rawList.filter(tz => !!tz.short_list) : rawList;

        return interimList.map(
            ({ value, text, abbrev, utc_offset }) => ({
                key: value,
                value: value,
                text: `(${utc_offset.substring(0, utc_offset.lastIndexOf(':'))}) ${text.replace('_', ' ')}`,
                description: `(${abbrev})`,
            })
        );
    }

    static addOneHour = (unixDate) => {
        const momentUnix = moment.unix(unixDate);
        const resultMoment = moment(momentUnix).add(1, 'hour');
        return resultMoment.unix();
    }

    static addOneYear = (unixDate) => {
        const momentUnix = moment.unix(unixDate);
        const resultMoment = moment(momentUnix).add(1, 'years');
        return resultMoment.unix();
    }

    static subtractOneYear = (unixDate) => {
        const momentUnix = moment.unix(unixDate);
        const resultMoment = moment(momentUnix).subtract(1, 'years');
        return resultMoment.unix();
    }

    static getTime = (time, timezone) => {
        const myTimezone = timezone || DateTimeUtils.getTimeZone();
        const timeInput = time || new Date();
        const theTime = moment.tz(timeInput, myTimezone);

        return {
            //the time object here 
            'minute': theTime.format('mm'),
            'hour': theTime.format('HH'),
            'day': theTime.format('ddd'),
            'date': theTime.format('DD'),
            'month': theTime.format('MMMM'),
            'year': theTime.year(),
            'zone': theTime.format('z'),
            'formattedTime': theTime.format('hh:mma (z)'),
            'moment': theTime
        }

    }
}
